export const DEPARTMENT = {
  AFR: "Africana Studies",
  AMST: "American Studies",
  ANTH: "Anthropology",
  ANSO: "Anthropology and Sociology",
  ARAB: "Arabic",
  ARTH: "Art History",
  ARTS: "Art Studio",
  ASST: "Asian Studies",
  ASTR: "Astronomy",
  BIMO: "Biochemistry Molecular Biology",
  BIOL: "Biology",
  CHEM: "Chemistry",
  CHIN: "Chinese",
  CLAS: "Classics",
  COGS: "Cognitive Science",
  COMP: "Comparative Literature",
  CSCI: "Computer Science",
  CMAJ: "Contract Major",
  DANC: "Dance",
  ECON: "Economics",
  ENGL: "English",
  ENVI: "Environmental Studies",
  RLFR: "French",
  GEOS: "Geosciences",
  GERM: "German",
  GBST: "Global Studies",
  CLGR: "Greek",
  CRHE: "Hebrew",
  CRHI: "Hindi",
  HIST: "History",
  HSCI: "History of Science",
  INTR: "Interdisciplinary Studies",
  RLIT: "Italian",
  JAPN: "Japanese",
  JWST: "Jewish Studies",
  JLST: "Justice and Law",
  CRKO: "Korean",
  CLLA: "Latin",
  LATS: "Latina/o Studies",
  LEAD: "Leadership Studies",
  MAST: "Maritime Studies",
  MATH: "Mathematics",
  MUS: "Music",
  NSCI: "Neuroscience",
  PHIL: "Philosophy",
  PHYS: "Physics",
  POEC: "Political Economy",
  PSCI: "Political Science",
  CRPO: "Portuguese",
  PSYC: "Psychology",
  PHLH: "Public Health",
  REL: "Religion",
  RUSS: "Russian",
  STS: "Science and Technology Studies",
  SOC: "Sociology",
  RLSP: "Spanish",
  STAT: "Statistics",
  CRSW: "Swahili",
  THEA: "Theatre",
  WGSS: "Women’s, Gender and Sexuality Studies",
};
